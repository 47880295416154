import { useAuth } from '@context/AuthContext';
import { AuthType } from '@helpers/AuthClient';
import LoginView from '@views/LoginView';
import { navigate, PageProps } from 'gatsby';
import React, { FC, useEffect } from 'react';
import { DASHBOARD_PAGE } from '../../constants';

interface LocationState {
  email?: string;
  message?: string;
}

const LoginPage: FC<PageProps<null, null, LocationState | null>> = ({ location: { state } }) => {
  const { isAuthenticated, isReady } = useAuth();

  useEffect(() => {
    if (isReady && isAuthenticated) {
      const queryParams = new URLSearchParams(window.location.search);

      navigate(queryParams.get('continueTo') ?? DASHBOARD_PAGE);
    }
  }, [isAuthenticated, isReady]);

  return isReady && !isAuthenticated ? (
    <LoginView
      authType={AuthType.Web}
      defaultValues={{ email: state?.email }}
      message={state?.message}
    />
  ) : null;
};

export default LoginPage;
